import React, { useState, useEffect } from 'react';
import {
  Container,
  Grid,
  Typography,
  Paper,
  Box,
  Button,
  Tabs,
  Tab,
  TextField,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CircularProgress,
} from '@mui/material';
import {
  collection,
  query,
  where,
  getDocs,
  doc,
  updateDoc,
} from 'firebase/firestore';
import { db } from '../firebase';

const UserManager = () => {
  const [users, setUsers] = useState([]);
  const [pendingUsers, setPendingUsers] = useState([]);
  const [unverifiedUsers, setUnverifiedUsers] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [tabIndex, setTabIndex] = useState(0);
  const [selectedUser, setSelectedUser] = useState(null);
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [updatedUser, setUpdatedUser] = useState({});
  const [fetching, setFetching] = useState(true);

  /**
   * Lookup all possible matching reference codes from 'licensees' by:
   *  1) EMAIL
   *  2) FIRSTNAME + SURNAME (split from fullName)
   *  3) SURNAME only
   *
   * We'll accumulate all found codes in an array, removing duplicates.
   */
  const lookupAllMatches = async (email, fullName) => {
    const referenceCodesFound = [];
    const searchReasons = []; // For verbose "search results" text if desired

    try {
      const licenseesRef = collection(db, 'licensees');
      const lowercasedEmail = email.toLowerCase();
      const nameParts = (fullName || '').trim().split(' ');
      const firstNamePart = nameParts[0]?.toUpperCase() || '';
      const surnamePart =
        nameParts.length > 1 ? nameParts.slice(1).join(' ').toUpperCase() : '';

      //
      // 1) Match by EMAIL
      //
      const emailQuery = query(licenseesRef, where('EMAIL', '==', lowercasedEmail));
      const emailSnapshot = await getDocs(emailQuery);
      if (!emailSnapshot.empty) {
        emailSnapshot.forEach((docSnap) => {
          const data = docSnap.data();
          if (data['REFERENCE CODE']) {
            referenceCodesFound.push(data['REFERENCE CODE']);
            searchReasons.push('matched by email');
          }
        });
      }

      //
      // 2) Match by FIRSTNAME + SURNAME
      //    (only attempt if we have at least 2 tokens in fullName)
      //
      if (nameParts.length > 1) {
        const fullNameQuery = query(
          licenseesRef,
          where('FIRSTNAME', '==', firstNamePart),
          where('SURNAME', '==', surnamePart)
        );
        const fullNameSnapshot = await getDocs(fullNameQuery);
        if (!fullNameSnapshot.empty) {
          fullNameSnapshot.forEach((docSnap) => {
            const data = docSnap.data();
            if (data['REFERENCE CODE']) {
              referenceCodesFound.push(data['REFERENCE CODE']);
              searchReasons.push('matched by first+surname');
            }
          });
        }
      }

      //
      // 3) Match by SURNAME ONLY
      //    (in case the user doesn't match exactly with first+surname or email)
      //
      if (surnamePart) {
        const surnameQuery = query(
          licenseesRef,
          where('SURNAME', '==', surnamePart)
        );
        const surnameSnapshot = await getDocs(surnameQuery);
        if (!surnameSnapshot.empty) {
          surnameSnapshot.forEach((docSnap) => {
            const data = docSnap.data();
            if (data['REFERENCE CODE']) {
              referenceCodesFound.push(data['REFERENCE CODE']);
              searchReasons.push('matched by surname only');
            }
          });
        }
      }

      // Remove duplicates
      const uniqueCodes = Array.from(new Set(referenceCodesFound));

      // If no codes found at all
      if (uniqueCodes.length === 0) {
        return {
          referenceCodesFound: [],
          searchResult: 'No matches found',
        };
      }

      // Merge search reasons into a unique descriptive string
      const uniqueReasons = Array.from(new Set(searchReasons)).join(', ');

      return {
        referenceCodesFound: uniqueCodes,
        searchResult: `Found matches (${uniqueReasons})`,
      };
    } catch (error) {
      console.error('Error looking up email or name:', error);
      return {
        referenceCodesFound: [],
        searchResult: 'Error occurred during lookup',
      };
    }
  };

  // Function to fetch users and automatically look up their reference codes
  const fetchUsers = async () => {
    try {
      setFetching(true);
      const usersRef = collection(db, 'users');

      const allUsersSnapshot = await getDocs(usersRef);
      const allUsersList = await Promise.all(
        allUsersSnapshot.docs.map(async (docSnap) => {
          const userData = docSnap.data();
          const { referenceCodesFound, searchResult } = await lookupAllMatches(
            userData.email || '',
            userData.fullName || ''
          );

          return {
            id: docSnap.id,
            ...userData,
            referenceCodesFound, // array of possible matches
            searchResult,
          };
        })
      );

      const pendingUsersList = allUsersList.filter((user) => user.status === 'pending');
      const unverifiedUsersList = allUsersList.filter((user) => !user.emailVerified);

      setUsers(allUsersList);
      setPendingUsers(pendingUsersList);
      setUnverifiedUsers(unverifiedUsersList);
      setAllUsers(allUsersList);
    } catch (error) {
      console.error('Error fetching users:', error);
    } finally {
      setFetching(false);
    }
  };

  useEffect(() => {
    fetchUsers();
  }, []);

  const updateStatus = async (userId, newStatus) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { status: newStatus });
      fetchUsers();
    } catch (error) {
      console.error('Error updating status:', error);
    }
  };

  const openEditDialog = (user) => {
    setSelectedUser(user);
    setUpdatedUser(user);
    setEditDialogOpen(true);
  };

  const handleEditChange = (field, value) => {
    setUpdatedUser((prev) => ({ ...prev, [field]: value }));
  };

  const saveUpdates = async () => {
    if (selectedUser && updatedUser) {
      try {
        const userRef = doc(db, 'users', selectedUser.id);
        await updateDoc(userRef, updatedUser);
        setEditDialogOpen(false);
        fetchUsers();
      } catch (error) {
        console.error('Error updating user:', error);
      }
    }
  };

  // Function to directly update the referenceCode in the 'users' collection
  const updateReferenceCode = async (userId, newCode) => {
    try {
      const userRef = doc(db, 'users', userId);
      await updateDoc(userRef, { referenceCode: newCode });
      fetchUsers();
    } catch (error) {
      console.error('Error updating reference code:', error);
    }
  };

  const handleTabChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  // Renders one user card with possible matches
  const renderUserCard = (user) => (
    <Grid item xs={12} md={6} key={user.id}>
      <Paper sx={{ p: 2 }}>
        <Typography variant="h6">{user.fullName}</Typography>
        <Typography>Email: {user.email}</Typography>
        <Typography>
          Status: {user.status} | Email Verified: {user.emailVerified ? 'Yes' : 'No'}
        </Typography>

        {/* Always display the user's existing reference code (from 'users' collection) */}
        <Typography sx={{ mt: 1 }}>
          Existing Reference Code: {user.referenceCode || 'N/A'}
        </Typography>

        {/* Display the search result info */}
        {user.searchResult && (
          <Typography sx={{ mt: 1 }}>
            Search results: {user.searchResult}
          </Typography>
        )}

        {/* Display all the reference codes that were found */}
        {user.referenceCodesFound && user.referenceCodesFound.length > 0 && (
          <Box sx={{ mt: 2 }}>
            {user.referenceCodesFound.map((code, idx) => (
              <Box key={idx} sx={{ display: 'flex', alignItems: 'center', mt: 1 }}>
                <Typography>Possible Code: {code}</Typography>
                <Button
                  variant="contained"
                  color="secondary"
                  sx={{ ml: 2 }}
                  onClick={() => updateReferenceCode(user.id, code)}
                >
                  Update Ref Code
                </Button>
              </Box>
            ))}
          </Box>
        )}

        <Box sx={{ mt: 2 }}>
          {/* Approve Button */}
          <Button
            variant="contained"
            color="primary"
            onClick={() => updateStatus(user.id, 'approved')}
            sx={{ mr: 2 }}
          >
            Approve
          </Button>

          {/* Edit Button */}
          <Button
            variant="outlined"
            color="primary"
            onClick={() => openEditDialog(user)}
          >
            Edit
          </Button>
        </Box>
      </Paper>
    </Grid>
  );

  if (fetching) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Container>
      <Typography variant="h4" sx={{ mb: 4 }}>
        User Management
      </Typography>

      <Tabs
        value={tabIndex}
        onChange={handleTabChange}
        indicatorColor="primary"
        textColor="primary"
        centered
        sx={{ mb: 3 }}
      >
        <Tab label="Pending Users" />
        <Tab label="Unverified Users" />
        <Tab label="All Users" />
      </Tabs>

      {tabIndex === 0 && (
        <Grid container spacing={2}>
          {pendingUsers.map(renderUserCard)}
        </Grid>
      )}
      {tabIndex === 1 && (
        <Grid container spacing={2}>
          {unverifiedUsers.map(renderUserCard)}
        </Grid>
      )}
      {tabIndex === 2 && (
        <Grid container spacing={2}>
          {allUsers.map(renderUserCard)}
        </Grid>
      )}

      {/* Edit Dialog */}
      <Dialog open={editDialogOpen} onClose={() => setEditDialogOpen(false)} maxWidth="sm" fullWidth>
        <DialogTitle>Edit User</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            label="Full Name"
            value={updatedUser.fullName || ''}
            onChange={(e) => handleEditChange('fullName', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Email"
            value={updatedUser.email || ''}
            onChange={(e) => handleEditChange('email', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Status"
            value={updatedUser.status || ''}
            onChange={(e) => handleEditChange('status', e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Reference Code"
            value={updatedUser.referenceCode || ''}
            onChange={(e) => handleEditChange('referenceCode', e.target.value)}
            sx={{ mb: 2 }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setEditDialogOpen(false)} color="secondary">
            Cancel
          </Button>
          <Button onClick={saveUpdates} color="primary">
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </Container>
  );
};

export default UserManager;
