import React, { useState, useEffect } from 'react';
import { useAuth } from '../contexts/AuthContext';
import { db, storage } from '../firebase';
import { doc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes } from 'firebase/storage';
import { TextField, Button, Typography, Container, Box, Alert, CircularProgress } from '@mui/material';
import Papa from 'papaparse';

function DepositRequestForm() {
  const { currentUser } = useAuth();
  const [referenceCode, setReferenceCode] = useState('');
  const [formData, setFormData] = useState({
    Address: '',
    Name: '',
    Surname: '',
    Rent: '',
    Deposit: '',
    IBAN: '',
  });

  const [loading, setLoading] = useState(true);
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    const fetchUserAndLicenseeData = async () => {
      if (!currentUser) {
        setError('No user is logged in.');
        setLoading(false);
        return;
      }

      try {
        const userDoc = await getDoc(doc(db, 'users', currentUser.uid));
        if (userDoc.exists()) {
          const userData = userDoc.data();
          if (userData.referenceCode) {
            setReferenceCode(userData.referenceCode);

            const licenseeDoc = await getDoc(doc(db, 'licensees', userData.referenceCode));
            if (licenseeDoc.exists()) {
              const licenseeData = licenseeDoc.data();
              setFormData({
                Address: licenseeData.ADDRESS || '',
                Name: licenseeData.NAME || '',
                Surname: licenseeData.SURNAME || '',
                Rent: licenseeData.RENT || '',
                Deposit: licenseeData.DEPOSIT || '',
                IBAN: '', 
              });
            } 
          } else {
            setError('No reference code found for this user.');
          }
        } else {
          setError('No user data found in the database.');
        }
      } catch (err) {
        console.error(err);
        setError('Error fetching user/licensee data.');
      } finally {
        setLoading(false);
      }
    };

    fetchUserAndLicenseeData();
  }, [currentUser]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleSubmit = async () => {
    setMessage('');
    setError('');

    if (!referenceCode) {
      setError('No reference code available. Cannot create deposit request.');
      return;
    }

    const { Address, Name, Surname, Rent, Deposit, IBAN } = formData;
    if (!Address || !Name || !Surname || !Rent || !Deposit || !IBAN) {
      setError('Please fill in all fields before submitting.');
      return;
    }

    try {
      const today = new Date();
      const monthName = today.toLocaleString('default', { month: 'long' }).toUpperCase();
      
      const csvData = [{ Address, Name, Surname, Rent, Deposit, IBAN }];
      const csvString = Papa.unparse(csvData);
      const csvBlob = new Blob([csvString], { type: 'text/csv' });
      const storageRef = ref(storage, `DEPOSIT REQUESTS/${monthName}/${referenceCode}.csv`);

      await uploadBytes(storageRef, csvBlob, { contentType: 'text/csv' });
      setMessage('Your deposit request has been submitted successfully.');
    } catch (err) {
      console.error(err);
      setError('Failed to submit deposit request.');
    }
  };

  if (loading) return <CircularProgress />;

  return (
    <Container maxWidth="sm">
      <Box sx={{ mt: 4, mb: 4 }}>
        <Typography variant="h4" gutterBottom>
          Request Deposit Return
        </Typography>

        <TextField
          label="Address"
          name="Address"
          value={formData.Address}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Name"
          name="Name"
          value={formData.Name}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Surname"
          name="Surname"
          value={formData.Surname}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Rent"
          name="Rent"
          value={formData.Rent}
          onChange={handleChange}
          type="number"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="Deposit"
          name="Deposit"
          value={formData.Deposit}
          onChange={handleChange}
          type="number"
          fullWidth
          margin="normal"
          required
        />
        <TextField
          label="IBAN"
          name="IBAN"
          value={formData.IBAN}
          onChange={handleChange}
          fullWidth
          margin="normal"
          required
        />

        <Box sx={{ mt: 3 }}>
          <Button variant="contained" color="primary" onClick={handleSubmit}>
            Submit Deposit Request
          </Button>
        </Box>

        {/* Move the alerts below the button */}
        {error && <Alert severity="error" sx={{ mt:2 }}>{error}</Alert>}
        {message && <Alert severity="success" sx={{ mt:2 }}>{message}</Alert>}

      </Box>
    </Container>
  );
}

export default DepositRequestForm;
