import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
  TextField,
  Button,
  Autocomplete,
  Alert,
  Box,
  CircularProgress,
  Switch,
  FormControlLabel,
  Snackbar,
  FormGroup,
  FormControl
} from '@mui/material';
import { collection, getDocs, doc, setDoc } from 'firebase/firestore';
import { db } from '../firebase';
import { getFunctions, httpsCallable } from 'firebase/functions';

function GenerateRentLink() {
  // This component is for generating rent links for existing tenants
  // The fields will be autofilled and locked for Name, Email, Phone, Address

  const [property, setProperty] = useState('');
  const [referenceCode, setReferenceCode] = useState('');
  const [rentAmount, setRentAmount] = useState('');
  const [email, setEmail] = useState('');
  const [name, setName] = useState('');
  const [address, setAddress] = useState('');
  const [phone, setPhone] = useState('');
  const [paymentLink, setPaymentLink] = useState('');
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');
  const [propertyOptions, setPropertyOptions] = useState([]);
  const [referenceCodeOptions, setReferenceCodeOptions] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [sendEmail, setSendEmail] = useState(false);

  // New state to control whether staff can change rent
  const [changeRent, setChangeRent] = useState(false);

  // Generate a unique ID (timestamp-based) for each link so multiple links won't overwrite
  const generateUniqueId = () => {
    return new Date().toISOString().replace(/[:.]/g, '-');
  };

  useEffect(() => {
    console.log("Fetching property options on initial load...");
    const fetchOptions = async () => {
      try {
        const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
        const licensees = licenseesSnapshot.docs.map((doc) => doc.data());

        console.log("Total licensees fetched:", licensees.length);

        const properties = Array.from(
          new Set(
            licensees
              .map((licensee) => {
                if (licensee['REFERENCE CODE'] && typeof licensee['REFERENCE CODE'] === 'string') {
                  return licensee['REFERENCE CODE'].replace(/\d+$/, '');
                }
                return null;
              })
              .filter(Boolean)
          )
        );

        console.log("Property options derived:", properties);
        setPropertyOptions(properties);

        if (properties.length > 0) {
          const initialProperty = properties[0];
          console.log("Initial property set to:", initialProperty);
          const initialReferenceCodes = licensees
            .filter((licensee) =>
              licensee['REFERENCE CODE'].startsWith(initialProperty)
            )
            .map((licensee) => licensee['REFERENCE CODE']);

          console.log("Initial reference codes derived:", initialReferenceCodes);
          setReferenceCodeOptions(initialReferenceCodes);
        }
      } catch (error) {
        console.error('Error fetching properties and reference codes:', error);
        setMessage('Failed to fetch properties and reference codes');
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    const fetchReferenceCodes = async () => {
      console.log("Fetching reference codes for property:", property);
      try {
        const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
        const licensees = licenseesSnapshot.docs.map((doc) => doc.data());
        console.log("Total licensees:", licensees.length);

        const filteredReferenceCodes = licensees
          .filter((licensee) =>
            licensee['REFERENCE CODE'].startsWith(property)
          )
          .map((licensee) => licensee['REFERENCE CODE']);

        console.log("Filtered reference codes:", filteredReferenceCodes);
        setReferenceCodeOptions(filteredReferenceCodes);
      } catch (error) {
        console.error('Error fetching reference codes:', error);
      }
    };

    if (property) {
      fetchReferenceCodes();
    }
  }, [property]);

  useEffect(() => {
    const fetchDetails = async () => {
      if (!referenceCode) {
        console.log("No reference code selected, skipping fetch details.");
        return;
      }

      console.log("Fetching details for selected reference code:", referenceCode);
      try {
        const licenseesSnapshot = await getDocs(collection(db, 'licensees'));
        const licensees = licenseesSnapshot.docs.map((doc) => doc.data());
        console.log("Total licensees:", licensees.length);

        const selectedLicensee = licensees.find(
          (licensee) => licensee['REFERENCE CODE'] === referenceCode
        );

        if (selectedLicensee) {
          console.log("Selected licensee found:", selectedLicensee);
          setAddress(selectedLicensee['ADDRESS'] || '');
          setEmail(selectedLicensee['EMAIL'] || '');
          setName(selectedLicensee['NAME'] || '');
          setPhone(selectedLicensee['PHONE NUMBER'] || '');
          // Fetch the existing rent to lock it by default
          if (selectedLicensee['RENT']) {
            setRentAmount(String(selectedLicensee['RENT']));
          } else {
            setRentAmount('');
          }
        } else {
          console.log("No licensee found for this reference code.");
        }
      } catch (error) {
        console.error('Error fetching details for selected reference code:', error);
      }
    };

    fetchDetails();
  }, [referenceCode]);

  // Store each link under a unique sub-document
  const storeLinkInFirestore = async (collectionName, payload, linkUrl, paymentId) => {
    try {
      const now = new Date();
      const currentMonthName = now.toLocaleString('en-US', { month: 'long' }).toUpperCase();
      const currentYear = now.getFullYear();
      const uniqueId = generateUniqueId(); // ensures a unique doc for each link

      const depositDocRef = doc(
        collection(db, collectionName, String(currentYear), currentMonthName, payload.referenceCode),
        uniqueId
      );

      await setDoc(depositDocRef, {
        property: payload.property,
        referenceCode: payload.referenceCode,
        amount: payload.depositAmount, // It's actually rent amount in this context
        email: payload.email,
        name: payload.name,
        address: payload.address,
        phone: payload.phone,
        paymentLink: linkUrl,
        paymentId: paymentId,
        timestamp: now.toISOString()
      });

      console.log(`Successfully stored link in Firestore under ${collectionName}/${currentYear}/${currentMonthName}/${payload.referenceCode}/${uniqueId}`);
    } catch (error) {
      console.error("Error storing link in Firestore:", error);
    }
  };

  const handleGenerate = async () => {
    console.log("Generate rent link button clicked.");
    console.log("Current form values:", {
      property,
      referenceCode,
      rentAmount,
      email,
      name,
      address,
      phone
    });

    if (
      !rentAmount ||
      !email ||
      !name ||
      !address ||
      !phone ||
      !property ||
      !referenceCode
    ) {
      console.warn("Missing required fields, cannot generate link.");
      setMessage('All fields are required. Please fill in all fields.');
      return;
    }

    // Check phone number for a '+' country code
    if (!phone.startsWith('+')) {
      console.warn("Phone number does not start with '+', cannot proceed.");
      setMessage('Please include the country code (e.g., +353) in the phone number.');
      return;
    }

    try {
      setLoading(true);
      setMessage('');
      setPaymentLink('');

      const functionUrl = 'https://europe-west1-lobi-portal.cloudfunctions.net/generateDepositPaymentLink';

      const payload = {
        property,
        referenceCode,
        depositAmount: Number(rentAmount), // Reusing the same field name from the backend
        email,
        name,
        address,
        phone,
      };

      console.log('Payload sent to backend for rent link:', payload);
      const response = await axios.post(functionUrl, payload);

      const { paymentLinkUrl } = response.data;
      console.log("Rent payment link generated successfully:", paymentLinkUrl);
      setPaymentLink(paymentLinkUrl);
      setMessage('Rent payment link generated successfully!');

      // Extract Payment ID
      const paymentId = paymentLinkUrl.replace("https://payment.truelayer.com/links/", "");

      // Store in Firestore under generated_rent_links
      await storeLinkInFirestore('generated_rent_links', payload, paymentLinkUrl, paymentId);

      if (sendEmail) {
        console.log("Sending rent email notification as checkbox is selected...");
        await sendEmailNotification(payload, paymentLinkUrl);
      }

    } catch (error) {
      console.error('Error generating rent payment link:', error);
      setMessage('Failed to generate rent payment link');
    } finally {
      setLoading(false);
    }
  };

  const sendEmailNotification = async (payload, linkUrl) => {
    try {
      console.log("Preparing to send email via Firebase Functions...");
      const functions = getFunctions(undefined, 'europe-west1'); 
      const sendReminderEmailCallable = httpsCallable(functions, 'sendReminderEmail');

      const subject = "Rent Payment Link";
      const message = `Dear ${payload.name},\n\nPlease find your rent payment link below:\n${linkUrl}\n\nRegards,\nThe Team`;

      console.log("Sending email with subject:", subject, "to:", payload.email);
      const response = await sendReminderEmailCallable({
        email: payload.email,
        subject: subject,
        message: message,
      });
      console.log("Email sent successfully:", response.data);

      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error sending email:", error);
    }
  };

  return (
    <div>
      <h2>Generate Rent Payment Link (Existing Tenant)</h2>
      <Box mb={2}>
        <Autocomplete
          options={propertyOptions}
          value={property}
          onChange={(e, newValue) => {
            console.log("Property selected from autocomplete:", newValue);
            setProperty(newValue || '');
          }}
          renderInput={(params) => <TextField {...params} label="Property" fullWidth />}
        />
      </Box>
      <Box mb={2}>
        <Autocomplete
          options={referenceCodeOptions}
          value={referenceCode}
          onChange={(e, newValue) => {
            console.log("Reference Code selected from autocomplete:", newValue);
            setReferenceCode(newValue || '');
          }}
          renderInput={(params) => <TextField {...params} label="Reference Code" fullWidth />}
        />
      </Box>
      <TextField 
        value={name} 
        label="Name" 
        fullWidth 
        margin="normal" 
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField 
        value={email} 
        label="Email" 
        fullWidth 
        margin="normal" 
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField 
        value={phone} 
        label="Phone" 
        helperText="Phone field is locked to existing tenant" 
        fullWidth 
        margin="normal"
        InputProps={{
          readOnly: true,
        }}
      />
      <TextField 
        value={address} 
        label="Address" 
        fullWidth 
        margin="normal" 
        InputProps={{
          readOnly: true,
        }}
      />

      {/* Toggle for allowing staff to change the rent */}
      <FormControlLabel
        control={
          <Switch
            checked={changeRent}
            onChange={() => setChangeRent(!changeRent)}
          />
        }
        label="Change Rent?"
      />

      <TextField
        value={rentAmount}
        onChange={(e) => {
          console.log("Rent Amount changed to:", e.target.value);
          setRentAmount(e.target.value);
        }}
        label="Rent Amount"
        type="number"
        fullWidth
        margin="normal"
        InputProps={{
          readOnly: !changeRent,
        }}
      />

      <FormControl component="fieldset" style={{ marginTop: '16px' }}>
        <FormGroup>
          <FormControlLabel
            control={<Switch checked={sendEmail} onChange={() => setSendEmail(!sendEmail)} />}
            label="Send Email Notification"
          />
        </FormGroup>
      </FormControl>

      <Box mt={2}>
        <Button onClick={handleGenerate} disabled={loading} variant="contained" color="primary">
          {loading ? <CircularProgress size={24} /> : 'Generate Rent Link'}
        </Button>
      </Box>
      {message && (
        <Box mt={2}>
          <Alert severity={paymentLink ? 'success' : 'error'}>{message}</Alert>
        </Box>
      )}
      {paymentLink && (
        <Box mt={2}>
          <a href={paymentLink} target="_blank" rel="noreferrer">
            Open Rent Payment Link
          </a>
        </Box>
      )}
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert severity="success" onClose={() => setSnackbarOpen(false)}>
          Email Sent Successfully!
        </Alert>
      </Snackbar>
    </div>
  );
}

export default GenerateRentLink;
