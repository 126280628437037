import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "./LatestProfitCSV.css";

const LatestProfitCSV = () => {
  const [csvData, setCsvData] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);
  const [viewMode, setViewMode] = useState("all"); // "all" | "top10" | "bottom10"

  // Replace with your CSV file URL
  const CSV_FILE_URL =
    "https://firebasestorage.googleapis.com/v0/b/lobi-portal.appspot.com/o/tracking_profit%2Fdaily%2Flatest.csv?alt=media";

  useEffect(() => {
    const fetchCSV = async () => {
      try {
        const response = await fetch(CSV_FILE_URL);
        if (!response.ok) {
          throw new Error(
            `Failed to fetch CSV (HTTP ${response.status}): ${response.statusText}`
          );
        }

        const csvText = await response.text();
        const firstLine = csvText.split("\n")[0];
        const delimiter = firstLine.includes("\t") ? "\t" : ",";

        Papa.parse(csvText, {
          delimiter,
          header: true,
          skipEmptyLines: true,
          complete: (results) => {
            if (results.errors.length) {
              console.warn("CSV Parsing issues:", results.errors);
            }

            // Determine the headers from the first line
            const headers = firstLine.split(delimiter);

            // Normalize rows
            const cleanedData = results.data.map((row) => {
              const rowData = { ...row };
              // Replace any invalid or empty fields
              Object.keys(rowData).forEach((key) => {
                if (
                  rowData[key] === "Can't Calculate" ||
                  rowData[key] === "N/A" ||
                  rowData[key] === ""
                ) {
                  rowData[key] = "NA";
                }
              });
              // Ensure each row has all columns
              headers.forEach((header) => {
                if (!(header in rowData)) {
                  rowData[header] = "NA";
                }
              });
              return rowData;
            });

            setCsvData(cleanedData);
          },
          error: (err) => {
            setError(err);
          },
        });
      } catch (err) {
        setError(err);
      } finally {
        setLoading(false);
      }
    };

    fetchCSV();
  }, []);

  // Helper to safely parse a number from a cell (default to 0 if not a valid number).
  const parseNum = (value) => {
    const parsed = parseFloat(value);
    return isNaN(parsed) ? 0 : parsed;
  };

  // Sort the data by "Potential profit" descending
  const sortedByProfit = [...csvData].sort(
    (a, b) => parseNum(b["Potential profit"]) - parseNum(a["Potential profit"])
  );

  // Decide what data to show, based on viewMode
  let displayedData = [];
  if (viewMode === "all") {
    displayedData = csvData;
  } else if (viewMode === "top10") {
    displayedData = sortedByProfit.slice(0, 10);
  } else if (viewMode === "bottom10") {
    // For the bottom 10, sort ascending by potential profit
    const sortedAsc = [...csvData].sort(
      (a, b) => parseNum(a["Potential profit"]) - parseNum(b["Potential profit"])
    );
    displayedData = sortedAsc.slice(0, 10);
  }

  if (error) {
    return (
      <div className="error">
        <h3>🚨 Error loading CSV</h3>
        <p>{error.message || JSON.stringify(error)}</p>
      </div>
    );
  }

  if (loading) {
    return <div className="loading">Loading latest Track Profit CSV...</div>;
  }

  if (!csvData.length) {
    return <div className="warning">⚠️ No data available in CSV.</div>;
  }

  const headers = Object.keys(displayedData[0] || {});

  return (
    <div className="table-page-container">
      <h2>Latest Track Profit</h2>

      <div className="button-group">
        <button onClick={() => setViewMode("all")}>Show All</button>
        <button onClick={() => setViewMode("top10")}>Top 10 Most Profitable</button>
        <button onClick={() => setViewMode("bottom10")}>Top 10 Least Profitable</button>
      </div>

      <div className="table-container">
        <table className="responsive-table">
          <thead>
            <tr>
              {headers.map((colName) => (
                <th key={colName}>{colName}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {displayedData.map((row, i) => (
              <tr key={i}>
                {headers.map((colName) => (
                  <td key={colName}>{row[colName]}</td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default LatestProfitCSV;
