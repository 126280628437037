import React from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { useAuth } from '../contexts/AuthContext';
import { Alert, Container, CircularProgress, Box } from '@mui/material';

const PrivateRoute = ({ allowedRoles }) => {
  const { currentUser, userRole, userStatus } = useAuth();

  // If there is no authenticated user, redirect to login
  if (!currentUser) {
    return <Navigate to="/login" />;
  }

  // If user status is pending or denied, show a message
  if (userStatus === 'pending') {
    return (
      <Container>
        <Alert severity="warning">
          Your account is awaiting approval. Once you've verified your email address,
          your account will be approved as soon as possible.
        </Alert>
      </Container>
    );
  }

  if (userStatus === 'denied') {
    return (
      <Container>
        <Alert severity="error">
          Your account has been denied. Please contact support if you believe this is a mistake.
        </Alert>
      </Container>
    );
  }

  // If allowedRoles is undefined, just let authenticated users through
  if (!allowedRoles) {
    return <Outlet />;
  }

  // If we have an authenticated user, but userRole is not yet determined,
  // show a loading spinner or a "checking access" message
  if (currentUser && !userRole) {
    return (
      <Container>
        <Box display="flex" justifyContent="center" alignItems="center" minHeight="50vh">
          <CircularProgress />
          <Box ml={2}>Checking your access level, please wait...</Box>
        </Box>
      </Container>
    );
  }

  // If the user's role doesn't match any of the allowed roles, show a message instead of redirecting
  if (!allowedRoles.includes(userRole)) {
    return (
      <Container>
        <Alert severity="error">
          You are not authorized to access this page.
        </Alert>
      </Container>
    );
  }

  // If all checks pass, render the requested page
  return <Outlet />;
};

export default PrivateRoute;
