import React, { useState, useEffect } from 'react';
import { db } from '../firebase'; // or wherever you import your Firestore config
import { collection, query, orderBy, limit, onSnapshot } from 'firebase/firestore';
import { 
  Typography, Table, TableHead, TableRow, TableCell, TableBody, 
  Box, CircularProgress 
} from '@mui/material';

const WebhooksPage = () => {
  const [webhookEvents, setWebhookEvents] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    // Query up to 50 most recent TrueLayer webhook events, newest first
    const colRef = collection(db, 'truelayer_webhook_events');
    const qRef = query(colRef, orderBy('createdAt', 'desc'), limit(50));

    // Subscribe in real time
    const unsubscribe = onSnapshot(qRef, (snapshot) => {
      const eventsData = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setWebhookEvents(eventsData);
      setLoading(false);
    }, (error) => {
      console.error("Error fetching TrueLayer events:", error);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return (
      <Box display="flex" justifyContent="center" alignItems="center" mt={4}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={{ margin: 4 }}>
      <Typography variant="h4" gutterBottom>TrueLayer Webhook Logs</Typography>
      {webhookEvents.length === 0 ? (
        <Typography>No events found.</Typography>
      ) : (
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>Timestamp</TableCell>
              <TableCell>Event Type</TableCell>
              <TableCell>Payment ID</TableCell>
              <TableCell>Raw Data</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {webhookEvents.map(event => {
              const createdAt = event.createdAt
                ? event.createdAt.toDate().toLocaleString()
                : 'N/A';
              const eventType = event.event_type || 'N/A';
              const paymentId = event.payment_id || 'N/A';

              return (
                <TableRow key={event.id}>
                  <TableCell>{createdAt}</TableCell>
                  <TableCell>{eventType}</TableCell>
                  <TableCell>{paymentId}</TableCell>
                  <TableCell>
                    <pre style={{ margin: 0, whiteSpace: 'pre-wrap' }}>
                      {JSON.stringify(event, null, 2)}
                    </pre>
                  </TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
      )}
    </Box>
  );
};

export default WebhooksPage;
